<template>
  <div id="main">
    <mv-full-page
      :isPointer="false"
      :isV="isV"
      :pages="pageCount"
      :page.sync="currentPage"
      :isCache="true"
      height="calc(100% - 0.72rem)"
      :transition="{
        duration: '500ms', // 动画时长
        timingFun: 'ease', // 动画速度曲线
        delay: '0s', // 动画延迟
      }"
      @rollEnd="onRollEnd"
      :config="{
        disabled: this.localValue
      }"
    >
      <template #page1>
        <div class="banner">
          <full-swiper :hover-scale="false" :datas="bannerList"></full-swiper>
        </div>
      </template>
      <template #page2>
        <div class="person">
          <div class="left">
            <img alt :src="require('@/assets/img/product/ekegel/person-1.png')"/>
            <div v-animate="{
            name: 'fadeInDown',
            delay: 500
            }" class="title">多场景锻炼<br/>每日 10-20 分钟</div>
          </div>
          <div class="right">
            <img alt :src="require('@/assets/img/product/ekegel/person-2.png')"/>
            <div v-animate="{
            name: 'fadeInDown',
            delay: 500
            }" class="title">24 小时客服<br/>在线指导</div>
            <div v-animate="{
            name: 'fadeInDown',
            delay: 500
            }" class="sub-title">澜渟 App 专属课程跟练</div>
          </div>
        </div>
      </template>
      <template #page3>
        <div class="scroll" @mousewheel="mousewheel">
          <img alt :src="require('@/assets/img/product/ekegel/person-3.png')"/>
          <div class="content">
            <p>柔滑触感 舒适体验</p>
            <div class="viewer">
              <div class="list" ref="swiper">
                <div class="item">
                  <img alt :src="require('@/assets/img/product/ekegel/swiper-1.png')"/>
                  <p class="title">硅胶绳</p>
                  <p class="sub-title">尾端硅胶材质</p>
                </div>
                <div class="item">
                  <img alt :src="require('@/assets/img/product/ekegel/swiper-2.png')"/>
                  <p class="title">柔感亲肤</p>
                  <p class="sub-title">表面光滑，无划伤  无锋棱，无毛刺</p>
                </div>
                <div class="item">
                  <img alt :src="require('@/assets/img/product/ekegel/swiper-3.png')"/>
                  <p class="title">温度调节</p>
                  <p class="sub-title">放入温水（5℃~37℃）加热，避免过凉造成不适</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #page4>
        <div class="zheng">
          <img alt :src="require('@/assets/img/product/ekegel/bottom.png')"/>
          <p class="title">一类医疗器械</p>
          <p class="sub-title">诚心  精心  用心</p>
        </div>
      </template>
    </mv-full-page>
  </div>
</template>

<script>
import '@/assets/css/animate.min.css';
import 'mv-full-page/lib-dist/mv-full-page.css';
import MvFullPage from 'mv-full-page';
import Vue from 'vue';
import { FullSwiper } from '@/components/full-swiper';
import directives from '@/utils/directives';
Vue.use(directives);
Vue.use(MvFullPage);

export default {
  data() {
    return {
      localValue: this.value,
      isV: true,
      currentPage: 1,
      pageCount: 4,
      swiperChange: false,
      activeSwiper: 1,
      swiperCount: 3,
      bannerList: [
        {
          path: require('@/assets/img/product/ekegel/banner-1.png'),
        },
        {
          path: require('@/assets/img/product/ekegel/banner-2.png'),
        },
      ],
    };
  },
  props: {
    value: { //数据源（必填）
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$parent.$emit('rollInit');
    // 监听是向上还是向下滚动页面

  },
  methods: {
    mousewheel(e) {
      if (this.currentPage !== 3) {
        return;
      }
      if (this.swiperChange) {
        return;
      }
      this.swiperChange = true;
      if (e.deltaY < 0) {
        // 向上
        this.activeSwiper--;
        if (this.activeSwiper < 1) {
          this.activeSwiper = 1;
          this.localValue = false;
          return;
        }
        this.$refs.swiper.style.transform = `translateY(-${(this.activeSwiper - 1) * 4.92}rem)`;
      } else {
        this.activeSwiper++;
        if (this.activeSwiper > this.swiperCount) {
          this.activeSwiper = this.swiperCount;
          this.localValue = false;
        }
        // 向下
        this.$refs.swiper.style.transform = `translateY(-${(this.activeSwiper - 1) * 4.92}rem)`;
      }
      setTimeout(() => {
        this.swiperChange = false;
      }, 400);
    },
    onRollEnd(page) {
      if (page == 3) {
        this.swiperChange = false;
        this.localValue = true;
      }
      this.$parent.$emit('rollPage', page);
      if (page === this.pageCount) {
        this.$parent.$emit('rollEnd');
      } else {
        this.$parent.$emit('rollTop');
      }
    },
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
    currentPage(val) {
      if (val === 1) {
        this.localValue = false;
      }
    },
  },
  components: {
    FullSwiper,
  },
};
</script>

<style lang="scss" scoped>
.full-page-wrapper {
  top: 0.72rem;
}

.banner {
  position: relative;
  width: 100%;
  height: 100%;
}

.person {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  gap: 0.12rem;

  >.left, >.right {
    position: relative;
    background: #EDECFD;
    width: 9.54rem;
    overflow: hidden;
    flex: 1;

    >img {
      position: absolute;
      width: 100%;
      object-fit: cover;
      bottom: 0;
    }

    >.title {
      position: absolute;
      top: 1rem;
      left: 0.9rem;
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
    }

    >.sub-title {
      position: absolute;
      top: 2.68rem;
      left: 0.9rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.33rem;
    }
  }
  >.right {
    >img {
      width: unset;
      height: 100%;
      right: 0;
    }
  }
}


.scroll {
  position: relative;
  width: 100%;
  height: 100%;
  background: #EDECFD;
  display: flex;
  align-items: center;

  >img {
    position: absolute;
    width: 12.89rem;
    object-fit: cover;
    left: 0;
    bottom: 0;
  }

  >.content {
    position: relative;
    left: 10.55rem;

    >p {
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
    }

    >.viewer {
      margin-top: 0.6rem;
      width: 5rem;
      height: 4.92rem;
      overflow: hidden;

      >.list {
        transition: all 0.4s ease-in-out;
        >.item {
          >img {
            width: 5rem;
            height: 3.88rem;
            border-radius: 0.24rem;
          }

          >.title {
            margin-top: 0.22rem;
            font-weight: 500;
            font-size: 0.28rem;
            color: #000000;
            line-height: 0.39rem;
          }

          >.sub-title {
            margin-top: 0.12rem;
            font-size: 0.2rem;
            color: #666666;
            line-height: 0.28rem;
          }
        }
      }
    }
  }
}

.zheng {
  position: relative;
  width: 100%;
  height: 100%;

  >img {
    position: absolute;
    width: 100%;
    object-fit: cover;
  }

  >.title {
    position: absolute;
    top: 1.2rem;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 0.56rem;
    color: #000000;
    line-height: 0.78rem;
  }

  >.sub-title {
    position: absolute;
    top: 2.1rem;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 0.28rem;
    color: #000000;
    line-height: 0.39rem;
  }
}

</style>
