var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"main"}},[_c('mv-full-page',{attrs:{"isPointer":false,"isV":_vm.isV,"pages":_vm.pageCount,"page":_vm.currentPage,"isCache":true,"height":"calc(100% - 0.72rem)","transition":{
      duration: '500ms', // 动画时长
      timingFun: 'ease', // 动画速度曲线
      delay: '0s', // 动画延迟
    },"config":{
      disabled: this.localValue
    }},on:{"update:page":function($event){_vm.currentPage=$event},"rollEnd":_vm.onRollEnd},scopedSlots:_vm._u([{key:"page1",fn:function(){return [_c('div',{staticClass:"banner"},[_c('full-swiper',{attrs:{"hover-scale":false,"datas":_vm.bannerList}})],1)]},proxy:true},{key:"page2",fn:function(){return [_c('div',{staticClass:"person"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/person-1.png')}}),_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
          name: 'fadeInDown',
          delay: 500
          }),expression:"{\n          name: 'fadeInDown',\n          delay: 500\n          }"}],staticClass:"title"},[_vm._v("多场景锻炼"),_c('br'),_vm._v("每日 10-20 分钟")])]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/person-2.png')}}),_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
          name: 'fadeInDown',
          delay: 500
          }),expression:"{\n          name: 'fadeInDown',\n          delay: 500\n          }"}],staticClass:"title"},[_vm._v("24 小时客服"),_c('br'),_vm._v("在线指导")]),_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
          name: 'fadeInDown',
          delay: 500
          }),expression:"{\n          name: 'fadeInDown',\n          delay: 500\n          }"}],staticClass:"sub-title"},[_vm._v("澜渟 App 专属课程跟练")])])])]},proxy:true},{key:"page3",fn:function(){return [_c('div',{staticClass:"scroll",on:{"mousewheel":_vm.mousewheel}},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/person-3.png')}}),_c('div',{staticClass:"content"},[_c('p',[_vm._v("柔滑触感 舒适体验")]),_c('div',{staticClass:"viewer"},[_c('div',{ref:"swiper",staticClass:"list"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/swiper-1.png')}}),_c('p',{staticClass:"title"},[_vm._v("硅胶绳")]),_c('p',{staticClass:"sub-title"},[_vm._v("尾端硅胶材质")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/swiper-2.png')}}),_c('p',{staticClass:"title"},[_vm._v("柔感亲肤")]),_c('p',{staticClass:"sub-title"},[_vm._v("表面光滑，无划伤 无锋棱，无毛刺")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/swiper-3.png')}}),_c('p',{staticClass:"title"},[_vm._v("温度调节")]),_c('p',{staticClass:"sub-title"},[_vm._v("放入温水（5℃~37℃）加热，避免过凉造成不适")])])])])])])]},proxy:true},{key:"page4",fn:function(){return [_c('div',{staticClass:"zheng"},[_c('img',{attrs:{"alt":"","src":require('@/assets/img/product/ekegel/bottom.png')}}),_c('p',{staticClass:"title"},[_vm._v("一类医疗器械")]),_c('p',{staticClass:"sub-title"},[_vm._v("诚心 精心 用心")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }